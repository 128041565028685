import * as React from "react";
import {
  Box,
  Card,
  Divider,
  InputBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Chip,
  IconButton,
  useMediaQuery,
  useTheme,
  Container,
  TableContainer,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

export default function Users(props) {
  const [data, setData] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [changed, setChanged] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const types = ["New Driver", "Driver Renewal", "New Rider", "Rider Renewal"];
  const [type, setType] = React.useState(types[0]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    setData(null);
    fetch(`/api/uberreport/paginated/${type}/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 401) {
          // Handle unauthorized status by logging out the user
          document.cookie =
            "nimda_arope=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          navigate("/"); // Redirect to the login page
        }
        if (!res.ok) {
          throw new Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [changed, currentPage, type]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleSearchChange = (event) => {
    if (event.target.value !== "") {
      fetch(`/api/uberreport/search/${event.target.value}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.status === 401) {
            document.cookie =
              "nimda_arope=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            navigate("/");
          }
          if (!res.ok) {
            throw new Error("Could not fetch data!!!");
          }
          return res.json();
        })
        .then((data) => {
          setData(data);
        })
        .catch((err) => {
          console.error(err.message);
        });
    } else {
      setData(null);
      setChanged(!changed);
    }
  };

  const getResultChipColor = (result) => {
    switch (result) {
      case "Pass":
        return "success";
      case "Fail":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Drivers/Riders</Typography>
        </Box>
        <Card
          style={{
            borderRadius: "16px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            padding: "16px",
          }}
        >
          <Box display="flex" alignItems="center" mb={2} flexWrap="wrap">
            <SearchIcon />
            <InputBase
              placeholder="Search by id or name or phone..."
              onChange={handleSearchChange}
              style={{ marginLeft: 8, flex: 1, minWidth: 150 }}
              sx={{ width: isMobile ? "100%" : "auto" }}
            />
          </Box>
          <Divider />
          <TableContainer
            sx={{ maxWidth: "85vw", width: "100%", overflowX: "auto" }}
            component={Paper}
          >
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell>SN</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>National ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Result</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.data.map((user) => (
                    <TableRow key={user.ID}>
                      <TableCell>{user.SerialNo}</TableCell>
                      <TableCell>{`${user.FirstName} ${user.MiddleName} ${user.Surname}`}</TableCell>
                      <TableCell>{user.Phone}</TableCell>
                      <TableCell>{user.NationalID}</TableCell>
                      <TableCell>{user.Date}</TableCell>
                      <TableCell>
                        <Chip
                          label={user.Result}
                          color={getResultChipColor(user.Result)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <TablePagination
            component="div"
            count={data != null ? data.total : 0}
            page={currentPage - 1}
            onPageChange={handlePageChange}
            rowsPerPage={12}
            rowsPerPageOptions={[12]}
          />
        </Card>
      </Stack>
    </Box>
  );
}
